.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background: white;
}

.modal-content textarea {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-actions button[type="button"] {
    background: #ccc;
}

.modal-actions button[type="submit"] {
    background: #007bff;
    color: #333;
}

.report-text {
    position: absolute;
    bottom: 0;
    right: 10px;
    background-color: white;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    z-index: 1000;
}