body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  padding: 20px;
  background-color: #333;
  color: white;
}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.map-container {
  flex: 1;
  position: relative;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.search-input {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  margin: 15px 0;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 50px;
}

.search-input:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.pin-count-label {
  position: absolute;
  top: 64px;
  left: 52px;
  z-index: 999;
  background-color: #ffffffa3;
  padding: 6px;
  border-radius: 2px;
}

.unesco-logo {
  width: 75px;
  height: 75px;
  margin-right: 4px;
  vertical-align: middle;
  position: absolute;
  right: 0;
}